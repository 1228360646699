$primary: #00a5bf
// Fonts

// Variables
@import 'variables'

// Functions
@import "functions"

// Bootstrap
@import '../libraries/bootstrap/scss/bootstrap'
@import '~bootstrap-table/dist/bootstrap-table.css'
@import '~bootstrap-table/dist/extensions/reorder-rows/bootstrap-table-reorder-rows.css'

// Layout
@import "layout"

// Overrides
@import "overrides"
