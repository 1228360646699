.text-decoration-none
  text-decoration: none

.list-group-header
  .list-group-item
    border: none
    transition: all 50ms

    &:hover
      background-color: rgba(117, 117, 117, 0.63) !important
      color: $fullWhite !important

    &.active
      background-color: $mpBlueHSLA !important

      &:hover
        background-color: $mpBlueHSLA !important

.data-badge::after
  $data: attr(data-badge) !default
  content: $data
  position: absolute
  justify-content: center
  align-items: center
  display: flex
  width: 22px
  height: 22px
  top: -10px
  right: -10px
  line-height: 22px
  font-size: 12px
  font-weight: 600
  border-radius: 50%
  background-color: $mpBlue
  z-index: 5
  color: #fff
  font-family: sans-serif

.noresize
  resize: none

ul.pagination
    display: flex
    flex-direction: row
    justify-content: center

.clickable-row
    cursor: pointer !important

table
  &.table
    &.table-sm
        &.table-fixed-width
          tr
            td, th
                position: relative
                width: 50% !important
        tr:first-of-type
          th, td
              border-top: 0

.container, .breadcrumb, .btn, .alert
    border-radius: 2px !important

.btn-group
    .btn
        border-radius: 0 !important

h3.text-secondary.text-center.border-primary
    border-bottom-width: 2px !important

.inactive
    color: rgba(105, 105, 105, 0.6)

.navbar-center
    margin-left: -0.75rem !important

.navbar-center a
    text-decoration: none !important
    color: white !important

.workplace-row td
    padding: 0.75rem
