.mobile-action-button
    display: block

@media (max-width: 800px)
    .mobile-action-button
        width: 100%
    .save-button
        position: fixed
        padding-top: 5px
        bottom: 0
        left: 50%
        margin-left: -50%
        z-index: 1

.nav-item-bg.active
    background-color: rgb(41, 40, 40) !important

.sidebar-hidden
    display: none
    transition: all .25s ease-in-out

#sidebar-wrapper .sidebar-heading
    background-color: #e9ecef

#sidebar-wrapper
    min-height: 100vh
    width: 100%

.sidebar-collapsed span
    opacity: 0

.sidebar-collapsed
    max-width: 60px
    transition: all 0.25s

.sidebar-expanded
    max-width: 250px
    transition: all 0.25s

.logo-image
    width: 100%

.small-logo-image
    max-height: 50px
    width: auto

#page-content-wrapper
    min-width: 100vw

#wrapper.toggled #sidebar-wrapper
    margin-left: 0

@media (min-width: 800px)
    #menu-toggle
        display: none !important
    .navbar
        display: none
    .sidebar-hidden
        display: block

@media (min-width: 768px)
    #sidebar-wrapper
        margin-left: 0

#page-content-wrapper
    min-width: 0
    width: 100%

#wrapper.toggled #sidebar-wrapper
    margin-left: -15rem

.background
    background-color: $gray
    z-index: 1
    background-size: cover
    background-position: center center
    background-repeat: no-repeat
    background-image: url("../images/sidebar/background.jpg")

.overlay
    width: 100%
    height: 100%
    background-color: $gray
    z-index: 2
    position: absolute
    opacity: .7

.image-details
    display: none

.image-details-arrow
    cursor: pointer

body, html
    #app
        min-height: 100vh

        .sidebar
            height: 100vh
            width: $sidebarWidth
            max-width: $sidebarWidth
            position: fixed

            .content
                z-index: 3
                position: absolute
                width: 100%
                height: 100%
                overflow-y: auto

                .logo
                    a
                        img
                            width: 50%
                            height: auto

                .logo-line
                    width: 85%
                    height: 1px
                    background-color: $gray

                .sidebar-wrapper
                    width: 85%

        main
            //padding: 20px 20px 20px $sidebarWidth+20px
            //width: calc(100vw - $sidebarWidth)
            //overflow-y: auto

            &.account
                width: 100vw
                padding: 20px
                min-height: 100vh

            .list-group-item
                &.overdue
                    background-color: #ff817b

                &.done
                    background-color: #77dd77
