// Colors
$body-bg: #f8fafc
$blue: #3490dc
$indigo: #6574cd
$purple: #9561e2
$pink: #f66d9b
$red: #e3342f
$orange: #f6993f
$yellow: #ffed4a
$green: #38c172
$teal: #4dc0b5
$cyan: #6cb2eb
$gray: #1a1a1a
$white: #f4f4f4
$fullWhite: #fff
$mpBlue: hsl(196, 99%, 45%)
$mpBlueHSLA: hsla(196, 99%, 45%, .4)

// Sizes
$sidebarWidth: 260px
